import { useProductVariantsStore } from "../store/productVariantsStore"
import { useTimeout } from "@vueuse/core"
import { useQuery } from "@tanstack/vue-query"
import { Ref } from "vue"

export function useLicencePolling(
  duration: number,
  interval: number,
  enabled: Ref<boolean>,
) {
  const licence = useLicenceStore()

  const productVariantsStore = useProductVariantsStore()

  const hasTimedOut = useTimeout(duration)

  const isQueryEnabled = computed(
    () =>
      enabled.value &&
      !hasTimedOut.value &&
      !productVariantsStore.activeVariant?.userHasLicence,
  )

  const query = useQuery({
    queryKey: [
      "licence",
      computed(() => productVariantsStore.activeVariant?.ean),
    ],
    enabled: isQueryEnabled,
    async queryFn() {
      if (!productVariantsStore.activeVariant?.userHasLicence) {
        await licence.setLicenceData()
      }
      return !!(
        productVariantsStore.activeVariant?.ean &&
        licence.hasLicenceByEan(
          productVariantsStore.activeVariant?.ean,
        )
      )
    },
    refetchInterval(query) {
      if (query.state.data) {
        return false
      }

      return interval
    },
  })

  const isPolling = computed(
    () => isQueryEnabled.value && !query.data.value,
  )

  return {
    data: query.data,
    error: query.error,
    isLoading: isPolling,
    refetch: query.refetch,
  }
}
