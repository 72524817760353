<script setup lang="ts">
import { isAuthorized, logout } from "~/utilities/auth"
import { ProductsList } from "~/models/Content/ContentProducts"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: false,
    default: undefined,
  },
  dark: {
    type: Boolean,
    default: true,
  },
  backButton: {
    type: Boolean,
    default: false,
  },
})

const variantStore = useProductVariantsStore()

const route = useRoute<"product">()
const title = computed(
  () =>
    props.product?.productTitleHtml ||
    props.product?.productTitle ||
    variantStore.activeProduct?.productTitle ||
    "",
)

const hasAtLeastOneReleasedProduct = computed(() => {
  if (variantStore.groupedProductVariants) {
    return variantStore?.groupedProductVariants[
      route.params.product as string
    ].hasAtLeastOneReleasedProduct
  }
})

const loginPageUrl = useLoginPageUrl()

const showLoginButton = computed(() => {
  return (
    loginPageUrl.value &&
    !route.path.includes("betaling") &&
    !route.path.includes("logg-inn")
  )
})
</script>

<template>
  <header
    class="flex items-center justify-center w-full h-full overflow-hidden"
  >
    <div
      class="flex h-full w-full max-w-[100em] items-center justify-between py-8 lg:pt-20 xl:py-10"
    >
      <div class="flex gap-4">
        <UButtonNew v-if="backButton" variant="quite" to="/"
          ><UIcon
            name="arrow-left"
            size="md"
            class="pl-4 hover:text-[--u-contrast]"
        /></UButtonNew>
        <ULogo :product="product" :dark="dark" />
      </div>
      <UButtonNew
        v-if="title && hasAtLeastOneReleasedProduct"
        :to="!isAuthorized ? loginPageUrl : null"
        size="lg"
        scale
        variant="quite"
        @click="
          () =>
            isAuthorized ? logout(variantStore.product ?? '') : null
        "
      >
        <span v-if="showLoginButton">{{
          !isAuthorized ? "Logg inn" : "Logg ut"
        }}</span>
        <span v-else>{{ isAuthorized ? "Logg ut" : "" }}</span>
      </UButtonNew>
    </div>
  </header>
</template>
