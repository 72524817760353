import type { MaybeRef } from "vue"
import { Article } from "~/types/article"
import { getMathTaskSolutions } from "~/mapping/math-task/mathTasks"
import { AssignmentSolutions } from "~/api/getMathTasks"
import { keys } from "~/utilities/injection"

export function useMathTask(articleData: MaybeRef<Article>) {
  const data = ref<AssignmentSolutions | undefined>()

  watch(
    toRef(articleData),
    async article => {
      if (article.mathTask && isMathTemplate(article.template)) {
        data.value = await getMathTaskSolutions(article.locationId)
      }
    },
    { immediate: true },
  )

  const mathTask = computed(() => toRef(articleData).value.mathTask)

  provide(keys.mathTask, mathTask)
  provide(keys.suggestedSolution, data)

  return data
}

type MathTemplates = "UMathTaskWithImageArticle" | "UMathTaskArticle"

function isMathTemplate(value: string): value is MathTemplates {
  return (
    value === "UMathTaskWithImageArticle" ||
    value === "UMathTaskArticle"
  )
}
