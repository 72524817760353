import { useQuery } from "@tanstack/vue-query"
import { graphqlClient } from "~/graphql/client/ecommerceClient"
import { GetEcommerceCatalogueDocument } from "~/graphql/documents/ecommerce/catalogue"
import {
  EcommerceProduct,
  EcommerceProductPrice,
  EcommercePriceVariants,
  EcommercePriceVariantIdentifier,
  EcommerceProductVariant,
} from "~/types/ecommerce/product"

interface CatalogueResponse {
  catalogue: {
    children: EcommerceProduct[]
  }
}

const KEY_MAPPING = {
  default: "default",
  discount: "discount",
  "consumer-discount": "consumerDiscount",
  "consumer-price": "consumerPrice",
} as const satisfies Record<
  EcommercePriceVariantIdentifier,
  keyof EcommercePriceVariants
>

const fetchEcommerceCatalogue = async (): Promise<
  EcommerceProduct[]
> => {
  const response = await graphqlClient<CatalogueResponse>(
    {
      query: GetEcommerceCatalogueDocument,
    },
    "/catalogue",
  )

  if ("errors" in response) {
    throw new Error("API error: " + JSON.stringify(response.errors))
  }

  if (!response.data) {
    throw new Error("No data in response")
  }

  if (!response.data.catalogue) {
    throw new Error("No catalogue in response data")
  }

  if (!Array.isArray(response.data.catalogue.children)) {
    throw new Error("Catalogue children is not an array")
  }

  return response.data.catalogue.children
}

export function useEcommerceCatalogue() {
  const query = useQuery({
    queryKey: ["ecommerceCatalogue"],
    queryFn: fetchEcommerceCatalogue,
  })

  const productPriceFilteredByEAN = (
    ean?: string,
  ): EcommerceProductPrice | undefined => {
    if (!ean || !query.data?.value) return undefined
    const variants = query.data.value
      .flatMap(product => product.variants)
      .find(variant => variant.ean === ean)
      ?.priceVariants?.reduce<EcommercePriceVariants>(
        (accumulatedVariants, currentVariant) => {
          const variantKey = KEY_MAPPING[currentVariant.identifier]
          if (
            variantKey &&
            typeof currentVariant.price === "number"
          ) {
            accumulatedVariants[variantKey] = currentVariant.price
          }
          return accumulatedVariants
        },
        {},
      )

    if (!variants) return undefined

    const discounted = variants.consumerDiscount ?? variants.discount
    const original = variants.consumerPrice ?? variants.default

    return {
      original,
      actual: discounted ?? original,
      discounted,
      discount:
        discounted && original
          ? {
              value: original - discounted,
              percentage: Math.floor(
                ((original - discounted) / original) * 100,
              ),
            }
          : undefined,
    }
  }

  const catalogueFilteredByEAN = (
    ean?: string,
  ): EcommerceProductVariant | undefined => {
    if (!ean || !query.data?.value) return undefined
    const variant = query.data.value
      .flatMap(product => product.variants)
      .find(variant => variant.ean === ean)

    if (!variant) return undefined
    return variant
  }

  return {
    data: query.data,
    isLoading: query.isLoading,
    error: query.error,
    productPriceFilteredByEAN,
    catalogueFilteredByEAN,
  }
}
