import type { Criterion, Query } from "~/models/Search"
import { formatSearchResults } from "~/mapping/formatSearchResults"
import { SearchResult } from "~/models/Content/SearchResult"
import useSearchHelper from "~/composables/useSearchHelper"
import { buildQuery } from "~/utilities/search-query"

export function useSearch<T>(path: string) {
  if (!path) {
    throw new Error("'path' option is required")
  }

  const { emptyQuery } = useSearchHelper()
  const isLoading = ref(false)
  const hasFailed = ref(false)

  async function fetchResults(
    query: Query,
    criterion: Criterion = {},
    limit: number = 10,
    offset: number = 0,
    depth: number = 0,
    maxDepth: number = 2,
  ): Promise<SearchResult> {
    isLoading.value = true
    hasFailed.value = false

    try {
      const results = await fetchAndFormatResults(
        query,
        criterion,
        limit,
        offset,
      )
      return await enrichWithRelations(results, depth, maxDepth)
    } catch (error) {
      hasFailed.value = true
      throw error
    } finally {
      isLoading.value = false
    }
  }

  // Helper functions
  async function fetchAndFormatResults(
    query: Query,
    criterion: Criterion,
    limit: number,
    offset: number,
  ): Promise<SearchResult> {
    const queryString = buildQuery(query, criterion, limit, offset)

    try {
      const response = await api.ibexaApi.get(
        `${path}?${queryString}`,
      )
      return formatSearchResults(response.data, queryString)
    } catch (error) {
      console.error("API request failed:", error)
      throw error
    }
  }

  async function enrichWithRelations(
    results: SearchResult,
    depth: number,
    maxDepth: number,
  ): Promise<SearchResult> {
    const relationsIds = results.items
      .map(item => item.relationIds)
      .flat()

    if (!relationsIds.length || depth >= maxDepth) {
      return results
    }

    try {
      results.relations = await fetchResults(
        emptyQuery,
        { contentIdCriterion: relationsIds },
        100,
        0,
        depth + 1,
        maxDepth,
      )
      return results
    } catch (error) {
      console.error("Failed to fetch relations:", error)
      results.relations = null
      return results
    }
  }

  return {
    isLoading,
    hasFailed,
    fetchResults,
  }
}

export default useSearch
