import type {
  FieldsTypes,
  SearchItem,
  SearchResult,
  TypedField,
} from "~/models/Content/SearchResult"
import type {
  BaseSearchResponse,
  LocationHit,
} from "~/models/Content/Response"
import {
  formatAuthorField,
  formatBinaryFileField,
  formatBooleanField,
  formatDateField,
  formatImageAssetField,
  formatImageField,
  formatIntegerField,
  formatMatrixField,
  formatMetadataField,
  formatObjectRelationField,
  formatRichTextField,
  formatSelectionField,
  formatStringField,
  formatTagsField,
  formatTextField,
  formatUrlField,
} from "./formatFieldValues"
import { ContentType } from "../../src/models/Content/ContentType"

export const formatSearchResults = (
  result: BaseSearchResponse,
  query: string,
): SearchResult => {
  if (!result?.View?.Result?.searchHits?.searchHit) {
    return {
      query,
      count: 0,
      items: [],
      relations: null,
    }
  }

  const formattedResults = {
    query,
    count: result.View.Result.count,
    items:
      result.View.Result.searchHits.searchHit.map(formatSearchItem),
    relations: null,
  }

  logSearchResults(formattedResults, {
    logEmpty: false,
    logSummary: true,
    logItems: true,
    logFields: true,
    contentTypes: [
      ContentType.Video,
      //"audio",
      // "quiz",
      //"math_task",
      //"solution",
      //"suggested_solution",
      //"sidenote",
      // "explainer",
      // "what_is",
      // "source",
      // "term",
      ContentType.Caption,
      ContentType.Article,
      ContentType.Folder,
      //ContentType.SidenotePerson,
      //ContentType.PageSection,
      //ContentType.Page,
      //ContentType.MarketingArticle,
      //ContentType.MarketingArticleSection,
    ],
    fieldTypes: [],
  })
  return formattedResults
}

const formatSearchItem = (hit: LocationHit): SearchItem => {
  const content = hit.value.Location.ContentInfo.Content
  const version = content?.CurrentVersion?.Version ?? {}
  const contentType =
    hit.value.Location?.ContentInfo?.ContentType?._identifier

  if (!version || !contentType) {
    throw new Error("Missing required content data")
  }

  const fields: Record<string, TypedField> = {}
  version.Fields.field.forEach(field => {
    fields[field.fieldDefinitionIdentifier] = formatFieldValue(
      field.fieldTypeIdentifier as keyof FieldsTypes,
      field.fieldValue,
      contentType,
      field.fieldDefinitionIdentifier,
    )
  })

  const relationIds =
    version.Relations?.Relation?.map(relation => {
      const href = relation.DestinationContent._href
      // Extract the ID from the end of the href path "/api/ezp/v2/content/objects/7073"
      return Number(href.split("/").pop())
    }) ?? []

  return {
    contentType,
    id: content._id,
    fields,
    relationIds,
    metadata: {
      locationId: hit.value.Location.id,
      pathString: hit.value.Location.pathString,
      sortField: hit.value.Location.sortField,
      sortOrder: hit.value.Location.sortOrder,
      published: content.publishedDate,
      modified: version.VersionInfo?.modificationDate || "",
      remoteId: content._remoteId,
      parentLocationId: hit.value.Location.ParentLocation
        ? Number(
            hit.value.Location.ParentLocation._href.split("/").pop(),
          )
        : null,
      mainLocationId: content.MainLocation
        ? Number(content.MainLocation._href.split("/").pop())
        : 0,
      priority: hit.value.Location.priority || 0,
      childCount: hit.value.Location.childCount || 0,
    },
  }
}

const formatFieldValue = (
  fieldType: keyof FieldsTypes,
  rawValue: unknown,
  contentType?: ContentType,
  fieldKey?: string,
): TypedField => {
  let value
  switch (fieldType) {
    case "ezstring":
      value = formatStringField(rawValue)
      break
    case "eztext":
      value = formatTextField(rawValue)
      break
    case "ezrichtext":
      value = formatRichTextField(rawValue)
      break
    case "ezimage":
      value = formatImageField(rawValue)
      break
    case "ezimageasset":
      value = formatImageAssetField(rawValue)
      break
    case "ezobjectrelation":
    case "ezobjectrelationlist":
      value = formatObjectRelationField(rawValue)
      break
    case "eztags":
      value = formatTagsField(rawValue)
      break
    case "ezboolean":
      value = formatBooleanField(rawValue)
      break
    case "ezauthor":
      value = formatAuthorField(rawValue)
      break
    case "ezselection":
      value = formatSelectionField(
        rawValue,
        contentType || "",
        fieldKey || "",
      )
      break
    case "ezmatrix":
      value = formatMatrixField(rawValue)
      break
    case "metadata":
      value = formatMetadataField(rawValue)
      break
    case "ezinteger":
      value = formatIntegerField(rawValue)
      break
    case "ezdate":
      value = formatDateField(rawValue)
      break
    case "ezurl":
      value = formatUrlField(rawValue)
      break
    case "ezbinaryfile":
      value = formatBinaryFileField(rawValue)
      break
    default:
      value = rawValue || ""
      break
  }

  return {
    type: fieldType,
    value,
    rawValue: String(rawValue),
  }
}

interface LogConfig {
  logEmpty?: boolean
  logSummary?: boolean
  logItems?: boolean
  logFields?: boolean
  contentTypes?: ContentType[]
  fieldTypes?: Array<keyof FieldsTypes>
}

const logSearchResults = (
  formattedResults: SearchResult,
  logConfig: Required<LogConfig>,
) => {
  // Skip logging if no results and logEmpty is false
  if (
    !logConfig.logEmpty ||
    (formattedResults.items.length === 0 && !logConfig.logEmpty)
  ) {
    return
  }

  // Skip logging if no items match the content type filter
  if (logConfig.contentTypes.length > 0) {
    const hasMatchingContentType = formattedResults.items.some(item =>
      logConfig.contentTypes.includes(item.contentType),
    )
    if (!hasMatchingContentType) {
      return
    }
  }

  // Print results in table format
  if (logConfig.logSummary) {
    const contentTypes = [
      ...new Set(
        formattedResults.items.map(item => item.contentType),
      ),
    ]
    console.table({
      query: formattedResults.query,
      contentTypes: contentTypes.join(", "),
      totalCount: formattedResults.count,
      itemsCount: formattedResults.items.length,
    })
  }

  // Print items details if any exist
  if (formattedResults.items.length > 0) {
    if (logConfig.logItems) {
      // Filter items by content type if specified
      const itemsToLog =
        logConfig.contentTypes.length > 0
          ? formattedResults.items.filter(item =>
              logConfig.contentTypes.includes(item.contentType),
            )
          : formattedResults.items

      console.table(
        itemsToLog.map(item => ({
          id: item.id,
          contentType: item.contentType,
          fieldCount: Object.keys(item.fields).length,
          relationCount: item.relationIds.length,
          relationIds: JSON.stringify(item.relationIds),
        })),
      )
    }

    if (logConfig.logFields) {
      // Filter items by content type if specified
      const itemsToLog =
        logConfig.contentTypes.length > 0
          ? formattedResults.items.filter(item =>
              logConfig.contentTypes.includes(item.contentType),
            )
          : formattedResults.items

      // Print fields for each item
      itemsToLog.forEach(item => {
        console.log(
          `Fields for item ${item.id} (${item.contentType}):`,
        )
        let filteredFields: Array<[string, TypedField]>

        if (logConfig.fieldTypes?.length) {
          filteredFields = Object.entries(item.fields).filter(
            ([_, field]) => logConfig.fieldTypes.includes(field.type),
          )
        } else {
          filteredFields = Object.entries(item.fields)
        }

        console.table(
          filteredFields.map(([key, field]) => ({
            key,
            type: field.type,
            value: JSON.stringify(field.value),
            rawValue: field.rawValue,
          })),
        )
      })
    }
  }
}
