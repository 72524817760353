<script setup lang="ts">
import { user } from "~/utilities/auth"
import hashString from "~/utilities/hash"
import { formatDate } from "~/utilities/composables"
import { useCheckoutPolling } from "~/composables/useCheckoutPolling"
import { useProductContentStore } from "~/store/productContentStore"

const products = useProductVariantsStore()
const content = useProductContentStore()
const route = useRoute<"article">()
const router = useRouter()
const licence = useLicenceStore()
const validTo = await useProductValidTo()

// Ensures that showModal initial value is set correctly
await router.isReady()

const isPayRedirect = computed(
  () => route.query["pay-redirect"] === "true",
)

watch(isPayRedirect, val => {
  if (val) {
    trackPurchase()
  }
})

const showModal = ref(isPayRedirect.value)

const removeRedirectParam = () => {
  const query = Object.assign({}, route.query)
  delete query?.["pay-redirect"]
  router.replace(query)
}

const trackPurchase = async () => {
  const today = new Date().toISOString().split("T")[0]
  const licenseToday = licence.licenceData?.find(
    license => license.startDate === today,
  )

  const price = products.productVariants.find(
    p => p.ean === licenseToday?.ean,
  )?.eCommerce?.price

  const userIdHash = await hashString(user.value?.uid || "")
  if (licenseToday) {
    rudderanalytics.track(
      "purchase",
      {
        transaction_id: licenseToday.ean + "-" + userIdHash,
        value: price ?? 649,
        items: [
          { item_id: licenseToday.ean, item_name: licenseToday.name },
        ],
      },
      () => {
        console.info("Purchase event has been tracked!")
      },
    )
  }
}

const { data: userHasLicence, isLoading } = useLicencePolling(
  60000,
  2000,
  isPayRedirect,
)

const { data: checkoutRejected, isLoading: isCheckoutLoading } =
  useCheckoutPolling(60000, 2000, isPayRedirect)

watch(showModal, val => {
  if (!val) {
    removeRedirectParam()
  }
})

const showError = computed(
  () =>
    !isLoading.value &&
    !isCheckoutLoading.value &&
    (userHasLicence.value === false ||
      checkoutRejected.value === true),
)

const showSuccess = computed(
  () =>
    !isLoading.value &&
    !isCheckoutLoading.value &&
    userHasLicence.value &&
    !checkoutRejected.value,
)

watchEffect(async () => {
  if (showSuccess.value) {
    await content.invalidateContent()
  }
})
</script>

<template>
  <u-modal
    v-model="showModal"
    custom-class="md:max-w-[60vw] md:max-h-[80vh]"
    custom-inner-class="!md:rounded-[20px]"
    custom-top-class="items-start bg-white bg-opacity-100 md:items-center md:justify-center md:bg-portal-indigo-900 md:bg-opacity-90"
    header-class="hidden md:flex"
    close-icon="close-circle"
    close-icon-size="30"
  >
    <section v-if="isLoading || isCheckoutLoading" class="body">
      <u-icon
        :key="1"
        name="spinner"
        class="m-auto flex justify-center stroke-red-60"
        size="xl"
      />
      <p>
        Vi sjekker status på betalingen din,
        <span class="font-bold">vennligst vent</span>...
      </p>
    </section>
    <section v-else>
      <div v-if="showSuccess" class="body">
        <div class="success" />
        <h2>Betalingen din er vellykket!</h2>
        <p>
          Du har nå tilgang til Kunne
          {{ products.activeProduct?.productTitle }} frem til slutten
          av semesteret.<br />
          <span class="font-bold">{{ formatDate(validTo) }}</span>
        </p>
        <UButtonNew
          pill
          @click="
            () => {
              showModal = false
            }
          "
        >
          Ta meg til produktet
        </UButtonNew>
      </div>

      <div v-if="showError" class="body">
        <h2>Ops, noe gikk galt!</h2>
        <p>
          Last inn siden på nytt, og se om du får tilgang til
          innholdet. <UContactCustomerService /> hvis problemet
          vedvarer, så hjelper vi deg.
        </p>
        <UButtonNew pill variant="filled" href="/om/kundeservice/">
          <template #prepend><UIcon name="user" /></template>
          Kundeservice
        </UButtonNew>
      </div>
    </section>
  </u-modal>
</template>

<style scoped lang="postcss">
.success {
  background-image: url("~/assets/images/success.svg");
  @apply h-[170px] w-[170px]
  animate-bounce
  bg-contain bg-no-repeat
  md:h-[200px] md:w-[200px];
}

.body {
  @apply flex flex-col items-center justify-center gap-y-10 bg-transparent pb-32 text-center text-[16px] md:text-[18px];

  h2 {
    @apply font-national2 text-[24px] font-bold leading-10 text-portal-indigo-900 md:text-[40px];
  }

  p,
  span {
    @apply max-w-md
    font-national2
    text-[20px]
    leading-normal
    md:text-[32px] md:leading-normal;
  }
}

.btn-square {
  @apply rounded-[5px]
  bg-red-80
  px-[20px]
  py-[4.5px] font-numeraWebRegular
  text-[18px]
  leading-normal
  md:hidden;
}
</style>
