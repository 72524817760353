import {
  array,
  enum as enumType,
  GenericSchema,
  InferOutput,
  intersect,
  lazy,
  nullable,
  number,
  object,
  optional,
  picklist,
  string,
} from "valibot"
import { ContentType } from "../ContentType"

// Base content schema
const NonRecursiveBaseItemSchema = object({
  title: string(),
  shortTitle: string(),
  contentId: number(),
  remoteId: string(),
  locationId: number(),
  mainLocationId: number(),
  parentLocationId: nullable(number()),
  contentTypeIdentifier: enumType(ContentType),
  pathString: string(),
  publishedDate: string(),
  label: string(),
  thumbnail: string(),
  thumbnailAlt: string(),
  sortField: picklist([
    "SCORE",
    "PRIORITY",
    "NAME",
    "PUBLISHED",
    "MODIFIED",
    "PATH",
  ]),
  sortOrder: picklist(["ASC", "DESC"]),
  priority: number(),
  relationsIds: array(number()),
  childCount: number(),
})

type NonRecursiveBaseItem = InferOutput<
  typeof NonRecursiveBaseItemSchema
>

// Recursive schemas must be typed first, so we separate those fields for less manual typing.
type BaseItem = NonRecursiveBaseItem & {
  children: BaseItem[]
  relatedItems: BaseItem[]
}

export const BaseItemSchema: GenericSchema<BaseItem> = intersect([
  NonRecursiveBaseItemSchema,
  object({
    children: array(lazy(() => BaseItemSchema)),
    relatedItems: array(lazy(() => BaseItemSchema)),
  }),
])

export const HtmlContentSchema = object({
  html: string(),
  plaintext: optional(string()),
})

export const ImageAssetSchema = nullable(
  object({
    id: nullable(number()),
    src: string(),
    alt: optional(string()),
    focalPointX: optional(number()),
    focalPointY: optional(number()),
    width: optional(number()),
    height: optional(number()),
  }),
)

export const TimestampSchema = object({
  description: string(),
  time: string(),
})

export const MetadataSchema = object({
  cmid: nullable(string()),
  contentItemId: string(),
  docType: string(),
  duration: string(),
  elementURI: string(),
  embedCode: nullable(string()),
  embedURL: string(),
  ezObjectId: nullable(string()),
  image: nullable(string()),
  materialID: nullable(string()),
  mimeType: string(),
  pageID: nullable(string()),
  rdfResource: string(),
  returnPath: string(),
})
