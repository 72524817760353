import type { BaseItem } from "~/models/Content/BaseItem"
import type { ContentImage } from "~/models/Content/ContentImage"
import type { ContentSvg } from "~/models/Content/ContentSvg"
import type { Criterion } from "~/models/Search"
import { Subtree } from "~/models/Content/Subtree"
import { ContentType } from "~/models/Content/ContentType"
import { useSearch } from "~/composables/useSearch"
import { mapContents } from "~/mapping/mapToContentTypes"
import useSearchHelper from "~/composables/useSearchHelper"
import * as Sentry from "@sentry/vue"
import type { SearchResult } from "~/models/Content/SearchResult"

/**
 * You should probably not extend this API.
 *
 * After the dimensional merge, where Content and ResourceItem are merged into the same
 * reality, there will be less and less need for a dedicated Content API. Instead, you
 * should probably look into extending the Resource and Section APIs.
 */
export default function useContentApi() {
  const { searchPath, emptyQuery } = useSearchHelper()

  const getContent = async <T extends BaseItem>(
    criterions: Criterion,
    limit: number = 100,
    offset: number = 0,
    fields: string[] = [],
    options: { includeChildren?: boolean; maxDepth?: number } = {},
  ): Promise<T[]> => {
    const { fetchResults } = useSearch<T[]>(searchPath)

    try {
      const results = await fetchResults(
        { ...emptyQuery, fields },
        criterions,
        limit,
        offset,
      )
      const mappedResults = mapRelationsToResults<T>(results)
      if (options.includeChildren) {
        const resultWithChildren = await attachChildren(
          mappedResults,
          fields,
          options.maxDepth,
        )
        if (
          criterions.contentTypeCriterion?.includes(
            ContentType.Article,
          )
        ) {
          //debugger
        }
        return resultWithChildren
      }

      return mappedResults ?? []
    } catch (error) {
      console.error(
        "Rest api request or mapping teh results failed:",
        error,
      )

      Sentry.captureException(error, {
        extra: {
          criterions,
          limit,
          offset,
          fields,
        },
      })
      return []
    }
  }

  const attachChildren = async <T extends BaseItem>(
    items: T[],
    fields: string[] = [],
    maxDepth?: number,
  ): Promise<T[]> => {
    if (items.length === 0) return items
    if (maxDepth === 0) return items

    // Get all locationIds in one go
    const locationIds = items.map(item => item.locationId)

    // Fetch all children in a single query
    const allChildren = await getContent<BaseItem>(
      {
        parentLocationIdCriterion: locationIds,
        sortField: "priority",
        sortOrder: "asc",
      },
      100,
      0,
      fields,
      {
        includeChildren: true,
        maxDepth: !maxDepth ? 0 : maxDepth - 1,
      },
    )

    // Create a map for quick lookup
    const childrenMap = allChildren.reduce(
      (acc, child) => {
        if (child.parentLocationId) {
          if (!acc[child.parentLocationId]) {
            acc[child.parentLocationId] = []
          }
          acc[child.parentLocationId].push(child)
        }
        return acc
      },
      {} as Record<number, BaseItem[]>,
    )

    // Assign children to their parents
    return items.map(item => ({
      ...item,
      children: childrenMap[item.locationId] ?? [],
    }))
  }

  const mapRelationsToResults = <T extends BaseItem>(
    results: SearchResult,
  ): T[] => {
    const mappedResults = mapContents<T>(results)

    if (results.relations) {
      const mapRelationsRecursively = (
        searchResult: SearchResult,
      ): T[] => {
        const mappedItems = mapContents<T>(searchResult)

        if (searchResult.relations) {
          const mappedRelations = mapRelationsRecursively(
            searchResult.relations,
          )
          mappedItems.forEach(item => {
            item.relatedItems = mappedRelations.filter(relation =>
              item.relationsIds.includes(relation.contentId),
            )
          })
        }

        return mappedItems
      }

      const mappedRelations = mapRelationsRecursively(
        results.relations,
      )
      mappedResults.forEach(item => {
        item.relatedItems = mappedRelations.filter(relation =>
          item.relationsIds.includes(relation.contentId),
        )
      })
    }

    return mappedResults
  }

  const getImageContent = async (
    contentId: number,
  ): Promise<ContentImage | undefined> => {
    return (
      await getContent<ContentImage>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Image],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  const getSvgContent = async (
    contentId: number,
  ): Promise<ContentSvg | undefined> => {
    return (
      await getContent<ContentSvg>(
        {
          contentIdCriterion: [contentId],
          contentTypeCriterion: [ContentType.Svg],
          subtreeCriterion: [Subtree.Content, Subtree.Media],
          mainLocationCriterion: true,
        },
        1,
        0,
      )
    )[0]
  }

  return {
    getContent,
    getImageContent,
    getSvgContent,
  }
}
