import { Relation } from "~/types/article"
import { BaseItem } from "~/models/Content/BaseItem"

enum TagNames {
  DIV = "DIV",
  SPAN = "SPAN",
}

export function sanitizeMathMl(mathML: string, parser: DOMParser) {
  const parsed = parser.parseFromString(mathML, "text/html")

  // This parsing is a temporarily fix to unwrap and parse the content as an HTML element
  parsed
    .querySelectorAll(
      'span[data-ezname="math_inline"], div[data-ezname="math_block"]',
    ) // Select both inline and block MathML elements
    .forEach(originalElement => {
      let mathElement // Declare a variable to hold the new element

      if (originalElement.tagName === TagNames.SPAN) {
        mathElement = document.createElement("span")
        mathElement.classList.add("math-inline")
      } else if (originalElement.tagName === TagNames.DIV) {
        mathElement = document.createElement("div")
        mathElement.classList.add("math-block")
      }

      if (originalElement.textContent) {
        // Directly assign the MathML content to the new element
        mathElement.innerHTML = originalElement.textContent.trim()

        // Replace the original element with the new wrapped structure
        originalElement.replaceWith(mathElement)
      }
    })

  // Unwrap all mstyle tags
  parsed.querySelectorAll("mstyle").forEach(el => {
    el.replaceWith(...Array.from(el.children))
  })

  // Ensure that mtd elements (cells) used in tables and matrices are left-aligned
  parsed.querySelectorAll("mtd").forEach(mtd => {
    mtd.style.textAlign = "left"
  })

  // Set font-family for matrices
  parsed.querySelectorAll("mo").forEach(mo => {
    if (
      mo.textContent === "[" ||
      mo.textContent === "]" ||
      mo.textContent === "(" ||
      mo.textContent === ")"
    ) {
      mo.style.fontFamily = "math"
    }
  })

  return parsed
}

/**
 * Converts HTML string to plaintext by using DOM manipulation
 * @param html HTML string to convert
 * @returns plaintext string
 */
export function htmlToPlaintext(html: string): string {
  if (!html) return ""
  const div = document.createElement("div")
  div.innerHTML = html
  return div.textContent || div.innerText || ""
}

/**
 * Creates a function that can be used to parse HTML strings with links to prepare them for displaying sidenotes.
 *
 * When calling the createHtmlFormatter function, a DOM parser is initialized, and a function is returned which can
 * be called to parse HTML strings in order to format it correctly to display sidenotes. This formatting function
 * which is returned loops over all "a" tags in the HTML string, and mutates the "a" tags inside it: Classes and
 * attributes are added based on which kind of identifier the matching relation has, and the href is updated to
 * contain a hash "#" as a prefix.
 *
 * @param {Relation[]} formattedRelations - A list of relations to the given content being parsed, for instance an
 * article. Elements in the list contains attributes like `resourceUrl`, `identifier` and the rest of the content
 * used in sidenotes.
 * @param {DOMParser} parser - The DOM parser to use for parsing the HTML string.
 *
 * @returns {function(html: string) => string} A function which takes the html string to format, and returns the parsed HTML.
 *
 * @example
 * // Example with a string that contains an "a" tag with a href, which is parsed to contain a hash and classes.
 * // Create the formatter, which initializes the DOM parser, and call the function with the HTML string to parse:
 *
 * const formatHtml = createHtmlFormatter(formattedRelations);
 * formatHtml("<a href='/kunne/sidenotater/exphil-uio/begrepsforklaring/indre-og-ytre-virkelighet'>[1]</a>");
 *
 * // Output:
 * "<a href='#/kunne/sidenotater/exphil-uio/begrepsforklaring/indre-og-ytre-virkelighet' class='text-link text-link--sidenote text-link--source' role='button'><sup>[1]</sup></a>"
 */
export function createHtmlFormatter(
  formattedRelations: Relation[],
  images: BaseItem[] = [],
  parser = new DOMParser(),
): (html: string) => string {
  const sourceUrls: string[] = []

  return (html: string) => {
    const parsed = sanitizeMathMl(html, parser)
    parsed.querySelectorAll("a").forEach(el => {
      const [isRef = false, appendText] =
        el.textContent?.trim().match(/^\[(?:\*|\d+)(.+)?]$/) ?? []

      const idFromHref = el
        .getAttribute("href")
        ?.match(/ezlocation:\/\/(\d+)/)?.[1]
      const relation = formattedRelations.find(
        ({ locationId }) => locationId.toString() === idFromHref,
      )

      if (relation) {
        el.href = `#${relation.locationId}`
        el.classList.add("text-link", "text-link--sidenote")
        el.setAttribute("role", "button")

        if (isRef) {
          relation.appendText = appendText
          relation.identifier = "source"
        }

        switch (relation.identifier) {
          case "source": {
            let index =
              sourceUrls.indexOf(relation.locationId.toString()) + 1
            if (!index) {
              sourceUrls.push(relation.locationId.toString())
              index = sourceUrls.length
            }

            relation.title = `${index}.`
            el.innerHTML = `<sup>[${index}]</sup>`
            el.classList.add("text-link--source")

            if (relation.appendText && relation.html) {
              const { html, appendText } = relation
              const index = html?.lastIndexOf(".")
              if (index >= 0) {
                relation.html = `${html.substring(
                  0,
                  index,
                )}, ${appendText}.${html.substring(index + 1)}`
              }
            }
            break
          }

          case "what_is": {
            const parsedSidenote = sanitizeMathMl(
              relation.resource.explanation,
              parser,
            )
            relation.resource.explanation =
              parsedSidenote.body.innerHTML

            break
          }
          case "explainer":
          case "sidenote": {
            relation.identifier = "explainer"
            el.classList.add("text-link--explainer")
            break
          }
        }
      }
    })

    if (images.length) {
      parsed
        .querySelectorAll('[data-ezelement="ezembed"]')
        .forEach(el => {
          const idFromHref = el
            .getAttribute("data-href")
            ?.match(/ezcontent:\/\/(\d+)/)?.[1]

          const relation = images.find(
            ({ contentId }) => contentId.toString() === idFromHref,
          )

          if (
            relation &&
            "src" in relation &&
            typeof relation.src === "string"
          ) {
            const img = document.createElement("img")
            img.src = relation.src
            if (
              "alt" in relation &&
              typeof relation.alt === "string"
            ) {
              img.alt = relation.alt
            }
            el.replaceWith(img)
          }
        })
    }

    return parsed.body.innerHTML
  }
}
