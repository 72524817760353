import { gql } from "graphql-tag"

export const GetEcommerceCatalogueDocument = gql`
  query {
    catalogue(language: "no-nb") {
      children {
        name
        type
        ... on Product {
          vatType {
            name
            percent
          }
          variants {
            name
            price
            ean: sku
            images {
              url
              altText
            }
            priceVariants {
              identifier
              price
            }
          }
        }
      }
    }
  }
`
