<script setup lang="ts">
import type { Article, Relation } from "~/types/article"
import { formatMathSidenotes } from "~/mapping/math-task/mathTasks"
import { SidenoteData } from "~/components/Sidenote/USidenoteContainer.vue"
import { useMathTask } from "~/composables/useMathTask"
import { FasitSidenote } from "~/models/FasitSidenote"

const props = defineProps({
  keyVal: {
    type: Number as PropType<number>,
  },
  data: {
    type: Object as PropType<Article> | undefined,
    default: () => {},
  },
  navState: {
    type: Boolean as PropType<boolean>,
  },
})

const route = useRoute<"article">()
const router = useRouter()

route.meta.index = Number(props.keyVal)

const articleData = computed(() => props.data)

provide("article", articleData)

const mathTasksSolutions = useMathTask(articleData)

const activeNote = ref<SidenoteData>()

const articleEl = ref<{ SIDENOTE_OFFSET: number } | null>(null)
const article = computed(() => {
  try {
    const templateName = props.data.template
    return defineAsyncComponent(
      () => import(`../Article/${templateName}.vue`),
    )
  } catch (e) {
    throw new Error(
      `Article template ${props.data.template} not found`,
    )
  }
})

function calcArticleOffset(): number {
  return articleEl.value?.SIDENOTE_OFFSET ?? 0
}

async function closeNote() {
  router.replace(route.fullPath.split("#")[0])
}

const sidenoteData = computed(() => {
  const relations: (Relation | FasitSidenote)[] = []
  const articleRelations = props.data.relations ?? []
  const mathRelations = formatMathSidenotes(
    mathTasksSolutions.value?.assignments,
  )

  relations.push(...articleRelations)

  if (mathRelations?.solutionSidenote) {
    relations.push(mathRelations.solutionSidenote)
  }

  if (mathRelations?.suggestedSolutionSidenotes) {
    relations.push(...mathRelations.suggestedSolutionSidenotes)
  }

  return relations
})

watch(
  () => route.hash,
  () => {
    nextTick(() => {
      if (!route.hash) {
        activeNote.value = undefined
        return
      }
      activeNote.value = sidenoteData.value.find(
        ({ locationId }) => `#${locationId}` === route.hash,
      )
    })
  },
  { immediate: true },
)
</script>

<template>
  <div
    v-if="!props.data"
    class="flex h-full w-full items-center justify-center bg-platform-paper p-2 font-national2compressed text-4xl text-black"
  >
    <h1 class="h-fit w-fit">
      Beklager, vi har problemer med å vise artikkelen du etterspurte.
    </h1>
  </div>
  <div
    v-else
    :id="`article-page-${data.id}`"
    class="grid h-full min-h-0 w-full overflow-hidden bg-platform-paper"
    :style="{
      '--sidebar-width': '475px',
      '--offset': calcArticleOffset(),
    }"
  >
    <div
      class="col-start-1 row-start-1 h-full min-h-full w-full transform text-base transition-transform md:text-xl lg:text-2xl"
      :class="{
        sidenoteactive: !!activeNote,
        'overflow-hidden': !!activeNote,
      }"
      @click.self="route.hash ? closeNote() : null"
    >
      <component
        :is="article"
        :ref="(el: any) => (articleEl = el)"
        :data="data"
        class="h-full w-full"
        :inert="!!route.hash"
      />
    </div>
    <USidenoteContainer
      :data="sidenoteData"
      :active-note="activeNote"
    />
  </div>
</template>

<style scoped>
.sidenoteactive {
  @screen md {
    --tw-translate-x: calc(var(--sidebar-width) * var(--offset) * -1);
  }
}
</style>
