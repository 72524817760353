<script setup lang="ts">
import type { Article } from "~/types/article"
import * as Sentry from "@sentry/vue"
import { client, isAuthorized } from "~/utilities/auth"
import { v5 } from "uuid"

const props = defineProps({
  quizId: {
    type: String,
    default: "",
  },
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

defineEmits(["sidenote"])

function isRiddleId() {
  // check if it's a UUID vs. Riddle ID
  return !props.data.quiz?.id.includes("-")
}

const route = useRoute<"article">()
const flytEl = ref<HTMLDivElement | null>(null)

let removeFlyt: () => void

onMounted(async () => {
  if (!isFeatureEnabled) {
    const script = document.createElement("script")
    script.setAttribute(
      "src",
      "https://www.riddle.com/embed/build-embedjs/embedV2.js",
    )
    document.head.appendChild(script)
  } else {
    const flytModule = await import("flyt/flyt")
    removeFlyt = flytModule?.default?.mount(flytEl.value, {
      flytId: isRiddleId()
        ? v5(props.data.quiz?.id, import.meta.env.VITE_FLYT_UUIDNS)
        : props.data.quiz?.id,
      theme: "kunne",
      endpoint: import.meta.env.VITE_FLYT_RESTAPI,
      callbacks: {
        onErrorCaptured(error) {
          Sentry.captureException(error)
        },
      },

      getFontFamily: (
        textStyleName: string,
        textStyle: { fontFamily: string },
      ) => {
        if (textStyle.fontFamily === "Numera Trial") {
          return "Numera Web Regular"
        }
        return textStyle.fontFamily
      },
      getAuthToken: () => client.getAccessToken(),
      getReportFeedback({ numberOfPassedItems, numberOfItems }) {
        const completed = numberOfPassedItems / numberOfItems

        if (completed === 1) {
          return {
            success: true,
            banner: { title: "Alt riktig!" },
            body: { title: "Nå var du dyktig" },
          }
        }

        if (completed > 0.8) {
          return {
            success: false,
            banner: { title: "Nesten alt riktig!" },
            body: { title: "Nå var du dyktig" },
          }
        }

        if (completed > 0.5) {
          return {
            success: false,
            banner: { title: "Litt å gå på!" },
            body: { title: "Ikke alt ble helt riktig denne gangen" },
          }
        }

        if (completed > 0) {
          return {
            success: false,
            banner: { title: "En del å gå på!" },
            body: { title: "Ikke alt ble helt riktig denne gangen" },
          }
        }

        if (completed === 0) {
          return {
            success: false,
            banner: { title: "Her kunne du ikke mye :/" },
            body: { title: "Må nok øve litt mer ja" },
          }
        }
      },
    })
  }
})

onBeforeUnmount(() => {
  removeFlyt?.()
})

defineExpose({
  SIDENOTE_OFFSET: 0.5,
})

const imageSize = computed(() => {
  switch (props.data.imageSize || "Small") {
    case "Small":
      return 12

    case "Medium":
      return 16

    case "Large":
    default:
      return 18
  }
})

const imageClasses = computed(() => {
  const start = 10 - imageSize.value / 2
  const end = 10 + imageSize.value / 2

  return `col-start-1 col-end-19 @md:col-start-${start} @md:col-end-${end}`
})

const productVariants = useProductVariantsStore()

const productName = computed(() => {
  return (
    (productVariants.groupedProductVariants &&
      productVariants.groupedProductVariants[
        route.params.product as string
      ]?.productTitle) ??
    ""
  )
})

const loginPageUrl = useLoginPageUrl()

const redirectUrl = computed(() => {
  const encodedFromPath = encodeURIComponent(route.fullPath)

  return {
    path: loginPageUrl.value,
    query: {
      fromPath: encodedFromPath,
    },
  }
})
</script>

<template>
  <div
    :id="`template-${data.id}`"
    class="u-article u-grid h-[90dvh] bg-platform-paper text-black"
  >
    <UArticleHeader
      :data="data"
      class="col-start-2 col-end-18 pt-[var(--grid-col-width)] @md:col-start-5 @md:col-end-15"
    />

    <u-article-image
      v-if="data.img?.src"
      :data="data"
      class="u-article__image u-article__image--inline"
      :class="imageClasses"
    />

    <u-rich-text
      class="col-start-2 col-end-18 pb-[var(--grid-col-width)] @md:col-start-5 @md:col-end-15"
      :html="data.body?.html"
    />

    <div
      v-if="!isAuthorized"
      class="richtext col-start-2 col-end-18 mt-20 flex flex-col items-center justify-center gap-6 rounded-lg bg-grey-20 p-4 py-20 pt-[var(--grid-col-width)] @md:col-start-3 @md:col-end-17"
    >
      <b
        >Logg deg inn på Kunne {{ productName }} for å få tilgang til
        Test deg selv:</b
      >
      <div class="flex gap-2">
        <UButtonNew class="!no-underline" :to="redirectUrl">
          Logg inn
        </UButtonNew>
      </div>
    </div>
    <div
      v-else
      ref="flytEl"
      class="col-start-2 col-end-18 mb-32 rounded-2xl bg-white p-8 @md:col-start-5 @md:col-end-15 md:p-16"
    ></div>
  </div>
</template>
