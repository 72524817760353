import type { Article, Relation } from "~/types/article"

import createMathTask from "./create-mathtask"
import brightnessFormat from "~/utilities/brightness-format"
import { createHtmlFormatter } from "~/utilities/dom-parsing"
import {
  onlyChar,
  capitalizeChar,
  camalize,
} from "~/utilities/composables"
import { Video, Audio, Quiz, Caption } from "~/types/media"
import { ContentType } from "~/models/Content/ContentType"
import { MathTaskIbexa } from "~/models/Content/schemas"
import { BaseItem } from "~/models/Content/BaseItem"
import { ImageAsset } from "~/models/Content/SearchResult"

/**
 *
 * @param node
 * @returns
 */
export function formatData(article: Article): Article {
  const { id, template, bg, color, relatedItems } = article

  const video = relatedItems?.find(
    r => r.contentTypeIdentifier === "video",
  ) as Video | undefined
  const audio = relatedItems?.find(
    r => r.contentTypeIdentifier === "audio",
  ) as Audio | undefined
  const quiz = relatedItems?.find(
    r => r.contentTypeIdentifier === "quiz",
  ) as Quiz | undefined

  if (video) {
    video.captions = video.relatedItems?.filter(
      r => r.contentTypeIdentifier === "caption",
    ) as Caption[]
  }

  const articleSidenotes = relatedItems.filter(isRelation)

  const mathRelations =
    article.children
      ?.filter(child => child.contentTypeIdentifier === "math_task")
      ?.flatMap(m => m.relatedItems ?? [])
      ?.filter(isRelation) ?? []

  const sidenotes = [...articleSidenotes, ...mathRelations].map(
    enrichSidenoteWithMedia,
  )

  const images = relatedItems.filter(
    it => it.contentTypeIdentifier === ContentType.Image,
  )

  /** Function to format links and references. This also mutates relations! */
  const formatHtml = createHtmlFormatter(sidenotes, images)

  const mathTasks = article.children?.filter(
    c => c.contentTypeIdentifier === ContentType.MathTaskIbexa,
  ) as MathTaskIbexa[]

  const mathTask = createMathTask(id, mathTasks, formatHtml)

  const intro = {
    html: formatHtml(article.intro?.html ?? ""),
    plaintext: article.intro?.plaintext,
  }

  const body = {
    html: formatHtml(article.body?.html ?? ""),
    plaintext: article.body?.plaintext,
  }

  return {
    ...article,
    template:
      "U" + onlyChar(capitalizeChar(camalize(template))) + "Article",

    bg: bg === "default" ? "paper" : bg,
    color: color === "default" ? "black" : color,
    intro,
    body,
    // Derived fields
    isDark: bg ? brightnessFormat(bg) : false,
    relations: sidenotes,
    video,
    audio,
    quiz,
    mathTask,

    // Required missing properties
    colorTag: undefined,
    hidden: false,
    restricted: false,
    frontendHref: undefined,
    subjectPageTitlePosition: undefined,
  }
}

function isRelation(r: BaseItem): r is Relation {
  return (
    r.contentTypeIdentifier === ContentType.SidenoteWhatIs ||
    r.contentTypeIdentifier === ContentType.SidenoteSource ||
    r.contentTypeIdentifier === ContentType.SidenotePerson ||
    r.contentTypeIdentifier === ContentType.SidenoteExplainer ||
    r.contentTypeIdentifier === ContentType.SidenoteTerm ||
    r.contentTypeIdentifier === ContentType.Sidenote
  )
}

function enrichSidenoteWithMedia(sidenote: Relation): Relation {
  const video = sidenote.relatedItems?.find(
    item => item.contentTypeIdentifier === "video",
  ) as Video | undefined

  const imageItem = sidenote.relatedItems?.find(
    item => item.contentTypeIdentifier === "image",
  ) as ImageAsset | undefined

  const image = imageItem
    ? {
        uri: imageItem.src,
        height: imageItem.height,
        width: imageItem.width,
        alternativeText: imageItem.alt,
        variation: { uri: imageItem.src },
      }
    : undefined

  return {
    ...sidenote,
    video: video ?? null,
    image,
    imageCaption: imageItem?.alt,
  }
}

/* TODO: Remove when not needed anymore
    export function formatDestinationContentToRelation(
  relations: BaseItem[],
): Relation[] {
  return relations.map(content => {
    switch (content.contentTypeIdentifier) {
      case "source": {
        const source = content as SourceRelation
        return {
          identifier: content.contentTypeIdentifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            type: content.type,
            author: content.author,
            title: content.title,
            edition: content.edition,
            publisher: content.publisher,
            publishYear: content.publishYear,
            publishDate: content.publishDate
              ? new Date(content.publishDate.timestamp * 1000)
              : null,
            url: content.url,
          },
        }
      }

      case "term": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            definition: content.definition.html5,
            explanation: content.explanation.html5,
            isFlashcard: content.isFlashcard,
          },
        }
      }

      case "person": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.name,
          html: null,
          resource: {
            birthYear: content.birthYear,
            deceasedYear: content.deceasedYear,
            about: content.about.html5,
          },
          image: content.image,
          imageCaption: content.imageCaption,
        }
      }

      case "what_is": {
        return {
          identifier: content._type.identifier,
          resourceUrl: content._url,
          title: content.title,
          html: null,
          resource: {
            explanation: content.explanation?.html5,
          },
          image: content.image,
        }
      }

      default: {
        return {
          identifier: "sidenote",
          title: content?.title ?? content.id,
          html: content?.richtext?.html5 ?? "<Tomt notat>",
          resourceUrl: content._url,
          image: content.image,
          video: content.video
            ? formatVideoData(content.video)
            : null,
          ...(content.richtext === null
            ? null
            : { html: content.richtext?.html5 }),
        }
      }
    }
  })
}*/
