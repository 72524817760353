import { acceptHMRUpdate, defineStore } from "pinia"
import { get } from "~/api/getUserMetadata"
import { set } from "~/api/setUserMetadata"
import type { Article } from "~/types/article"
import type {
  ReadArticle,
  PersonalizationSettings,
} from "~/types/readHistory"
import hashString from "~/utilities/hash"
import { monitorHistoryArraySize } from "~/utilities/composables"
import { cleanReadHistory } from "~/utilities/cleanReadHistory"

// Separate composable for read history management
function useReadHistory(user: Ref<any>) {
  const readHistory: Ref<ReadArticle[]> = ref([])
  const hashReadHistory = ref<string | undefined>(undefined)
  const hydrateReadHistoryInProgress = ref(false)
  let cacheKeyReadHistory = "anonymous-read-history"

  const initializeReadHistory = async (userId: string) => {
    cacheKeyReadHistory = `${userId}-read-history-v2`
    const value = (await get(cacheKeyReadHistory)) as
      | ReadArticle[]
      | undefined

    hydrateReadHistoryInProgress.value = true
    readHistory.value = value ?? []
    hashReadHistory.value = await hashString(JSON.stringify(value))
  }

  const syncReadHistory = async (userId?: string) => {
    if (hydrateReadHistoryInProgress.value) {
      hydrateReadHistoryInProgress.value = false
      return
    }

    const newReadHistoryHash = await hashString(
      JSON.stringify(readHistory.value),
    )
    if (hashReadHistory.value === newReadHistoryHash) return

    const cleanedHistory = cleanReadHistory(readHistory.value)
    await set(cacheKeyReadHistory, cleanedHistory, userId)
    hashReadHistory.value = newReadHistoryHash
    monitorHistoryArraySize(readHistory.value)
  }

  return {
    readHistory,
    hashReadHistory,
    hydrateReadHistoryInProgress,
    initializeReadHistory,
    syncReadHistory,
  }
}

// Separate composable for settings management
function useSettings(user: Ref<any>) {
  const settings: Ref<PersonalizationSettings> = ref({
    autoShowProgress: true,
    featureFlagAccess: false,
  })
  const hydrateSettingsInProgress = ref(false)
  let cacheKeySettings = "anonymous-settings"

  const initializeSettings = async (userId: string) => {
    cacheKeySettings = `${userId}-settings`
    const settingsValue = (await get(
      cacheKeySettings,
    )) as PersonalizationSettings
    if (settingsValue) {
      hydrateSettingsInProgress.value = true
      settings.value = settingsValue
    }
  }

  const syncSettings = async (userId?: string) => {
    if (hydrateSettingsInProgress.value) {
      hydrateSettingsInProgress.value = false
      return
    }
    await set(cacheKeySettings, settings.value, userId)
  }

  return {
    settings,
    hydrateSettingsInProgress,
    initializeSettings,
    syncSettings,
  }
}

export const usePersonalizationStore = defineStore(
  "personalization",
  () => {
    // Store dependencies
    const data = useProductContentStore()
    const products = useProductVariantsStore()
    const licence = useLicenceStore()
    const { user } = useAuth()

    // Initialize composables
    const { readHistory, initializeReadHistory, syncReadHistory } =
      useReadHistory(user)

    const { settings, initializeSettings, syncSettings } =
      useSettings(user)

    // State
    const activeArticle: Ref<Article | undefined> = ref(undefined)
    const lastActiveArticle: Ref<Article | undefined> = ref(undefined)
    const elapsedSeconds = ref(0)
    const timerRef: Ref<NodeJS.Timeout | null> = ref(null)

    // Computed
    const lastReadArticle = computed(() => {
      const doneArticles = readHistory.value.filter(
        ({ productId }) =>
          products.activeVariant?.locationId === productId,
      )
      return doneArticles.length ? doneArticles[0] : undefined
    })

    // Methods
    function clearReadHistory() {
      readHistory.value = []
      setTimeout(() => window.location.reload(), 500)
    }

    function addArticleToReadHistory(
      articleId: number,
      done?: boolean,
    ) {
      // Find article and subject data
      const article = data.allArticles.find(a => a.id === articleId)
      const subject = data.subjects.find(
        s => s.id === article?.parentLocationId,
      )

      if (!article || !subject || !products.activeVariant) {
        console.warn(
          "addArticleToReadHistory: article, subject or product not found, this is a bug.",
        )
        return
      }

      // Get done status from existing article if present
      const existingArticle = readHistory.value.find(
        a => a.id === articleId,
      )

      // Create new article array in one operation
      readHistory.value = [
        {
          id: article.id,
          productId: products.activeVariant.locationId,
          subjectId: subject.id,
          priority: subject.priority,
          articleTitle: article.title,
          subjectTitle: subject.title,
          done: done ?? existingArticle?.done ?? false,
        },
        ...readHistory.value.filter(a => a.id !== articleId),
      ]
    }

    // Watchers
    watch(
      user,
      async (newUser, oldUser) => {
        if (!oldUser?.uid && newUser?.uid) {
          await Promise.all([
            initializeReadHistory(newUser.uid),
            initializeSettings(newUser.uid),
          ])
        }
      },
      { immediate: true },
    )

    watch(
      () => readHistory.value,
      () => syncReadHistory(user.value?.uid),
      { deep: true },
    )
    watch(
      () => settings,
      () => syncSettings(user.value?.uid),
      { deep: true },
    )

    watch(
      activeArticle,
      (newArticle, oldArticle) => {
        if (newArticle) {
          lastActiveArticle.value = newArticle
          addArticleToReadHistory(newArticle.id)
        }

        if (!licence.hasValidLicence) return
        if (timerRef.value) clearTimeout(timerRef.value)

        if (newArticle && newArticle?.id !== oldArticle?.id) {
          if (!settings.value?.autoShowProgress) return

          elapsedSeconds.value = 0
          timerRef.value = setInterval(() => {
            elapsedSeconds.value++
            if (elapsedSeconds.value >= 8) {
              if (timerRef.value) clearTimeout(timerRef.value)
              elapsedSeconds.value = 0
              addArticleToReadHistory(newArticle.id, true)
            }
          }, 1000)
        }
      },
      { immediate: true },
    )

    return {
      readHistory,
      settings,
      activeArticle,
      lastActiveArticle,
      elapsedSeconds,
      lastReadArticle,
      clearReadHistory,
      addArticleToReadHistory,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(
    acceptHMRUpdate(usePersonalizationStore, import.meta.hot),
  )
}
