import {
  object,
  string,
  number,
  array,
  optional,
  picklist,
  InferOutput,
  intersect,
} from "valibot"
import {
  HtmlContentSchema,
  ImageAssetSchema,
  BaseItemSchema,
} from "./common.schema"

export const MathTaskIbexaSchema = intersect([
  BaseItemSchema,
  object({
    assignmentType: picklist([
      "Innlæring",
      "Mengdetrening",
      "none",
      "",
    ]),
    assignmentText: HtmlContentSchema,
  }),
])

export type MathTaskIbexa = InferOutput<typeof MathTaskIbexaSchema>

export const MathTaskIbexaSolutionSchema = intersect([
  BaseItemSchema,
  object({
    solutionTitle: string(),
    solutionText: HtmlContentSchema,
  }),
])

export type MathTaskIbexaSolution = InferOutput<
  typeof MathTaskIbexaSolutionSchema
>

export const MathTaskIbexaSuggestedSolutionSchema = intersect([
  BaseItemSchema,
  object({
    title: string(),
  }),
])

export type MathTaskIbexaSuggestedSolution = InferOutput<
  typeof MathTaskIbexaSuggestedSolutionSchema
>

export const MathTaskIbexaSuggestedSolutionSectionSchema = intersect([
  BaseItemSchema,
  object({
    sectionBody: HtmlContentSchema,
    sectionImage: optional(ImageAssetSchema),
  }),
])

export type MathTaskIbexaSuggestedSolutionSection = InferOutput<
  typeof MathTaskIbexaSuggestedSolutionSectionSchema
>

export const MathTaskAssignmentSchema = intersect([
  BaseItemSchema,
  object({
    id: number(),
    text: string(),
    type: picklist(["Innlæring", "Mengdetrening", "none", ""]),
    solution: optional(string()),
    priority: number(),
  }),
])

export const MathTaskSchema = intersect([
  BaseItemSchema,
  object({
    articleId: number(),
    assignmentType: string(),
    assignments: array(MathTaskAssignmentSchema),
  }),
])
