<script setup lang="ts">
import { ProductsList } from "~/models/Content/ContentProducts"
import UChooseInstitute from "./UChooseInstitute.vue"
import { useEcommerceCatalogue } from "~/composables/useEcommerceCatalogue"
import { useToastStore } from "~/store/toastStore"
import { formProductKeys } from "~/components/Hubspot/productForms"

export type ViewType = "list" | "detail"

const props = defineProps({
  product: {
    type: Object as PropType<ProductsList>,
    required: true,
  },
  viewType: {
    type: String as PropType<ViewType>,
    default: "list",
  },
  align: {
    type: String as PropType<"center" | "left">,
    default: "left",
  },
  showStatusDetails: {
    type: Boolean,
    default: false,
  },
})

const router = useRouter()
const showVariantModal = ref(false)
provide("showVariantModal", showVariantModal)

function goToProductContentButtonHandler() {
  if (props.product.variants.length === 1) {
    router.push(
      `/produkter/${props.product.productKey ?? "exphil"}/${
        props.product.variants[0].variantKey
      }`,
    )
  } else {
    showVariantModal.value = true
  }
}

const {
  productPriceFilteredByEAN,
  isLoading: isEcommerceCatalogueLoading,
  error: isEcommerceCatalogueError,
} = useEcommerceCatalogue()

const toastStore = useToastStore()
const ecommerceCatalogueErrorToastId = "ecommerce-catalogue-error"

watch(
  () => isEcommerceCatalogueError,
  newValue => {
    if (newValue) {
      toastStore.addToast({
        id: ecommerceCatalogueErrorToastId,
        message:
          "Vi klarte ikke å hente prisinformasjonen for produktet",
        type: "error",
      })
    }
  },
)

const hasValidLicence = computed(() => {
  return props.product.variants.some(p => p.userHasLicence)
})

const productVariantPrice = computed(() => {
  //first variant with an ean is used to set price
  return productPriceFilteredByEAN(
    props.product.variants.find(variant => variant.ean)?.ean,
  )
})

const isProductReleased = computed(() => {
  return props.product?.variants.some(p => p.isReleased)
})

const hasInterestForm = computed(() =>
  formProductKeys.find(it => it === props.product?.productKey),
)

const productHasOriginalPriceAboveZero = computed(() => {
  return (
    productVariantPrice.value?.original &&
    productVariantPrice.value?.original > 0
  )
})

const productHasDiscountPriceAboveZero = computed(() => {
  return (
    productVariantPrice.value?.discount &&
    productVariantPrice.value?.discount.value > 0
  )
})

const productGroupStatusDetails = computed(() => {
  const statusDetails = props.product.productGroupStatusDetails || ""
  const preSalePriceText =
    productHasOriginalPriceAboveZero.value &&
    productHasDiscountPriceAboveZero.value
      ? `Fullpris ${productVariantPrice.value?.original},-`
      : ""

  if (
    productHasDiscountPriceAboveZero.value ||
    props.showStatusDetails
  ) {
    return (
      preSalePriceText +
      (statusDetails && preSalePriceText ? ". " : "") +
      statusDetails
    )
  }
  return ""
})

const productHasFreeVariant = computed(() => {
  if (
    props.product.variants.some(
      variant => productPriceFilteredByEAN(variant.ean)?.actual === 0,
    )
  ) {
    return true
  }
  return false
})
</script>

<template>
  <div>
    <UChooseInstitute
      :product="product"
      :show-variant-modal="showVariantModal"
    />
    <div
      class="flex flex-wrap xl:flex-nowrap gap-x-4 lg:justify-between"
      :class="{
        'flex-col  items-center justify-center': align === 'center',
        'flex-col gap-y-6 lg:gap-y-8': viewType === 'detail',
        'gap-y-4 flex-col lg:flex-row': viewType === 'list',
      }"
    >
      <USimpleSkeleton v-if="isEcommerceCatalogueLoading" />
      <div
        v-else-if="
          product?.hasAtLeastOneReleasedProduct &&
          !isEcommerceCatalogueLoading
        "
        class="flex flex-col items-start gap-2 text-3xl font-extrabold text-left grow"
        :class="{
          'gap-y-1 lg:gap-y-2': viewType === 'list',
          'gap-y-2 lg:gap-y-4': viewType === 'detail',
        }"
      >
        <UPrice
          v-if="productVariantPrice"
          :price="productVariantPrice"
          :view-type="viewType"
        />
        <p
          v-if="
            productHasDiscountPriceAboveZero ||
            product.productGroupStatusDetails
          "
          class="font-normal text-[--theme-darker]"
          :class="{
            'text-lg lg:text-xl font-national2': viewType === 'list',
            'text-2xl lg:text-3xl font-national2condensed':
              viewType === 'detail',
          }"
        >
          {{ productGroupStatusDetails }}
        </p>
      </div>
      <div
        v-else-if="
          viewType === 'list' && !isEcommerceCatalogueLoading
        "
        class="flex grow items-center italic text-[--theme-dark]"
      >
        <p
          class="font-extrabold text-marketing-mobile-p1-p1-bold-m lg:text-marketing-desktop-p1-p1-bold-s font-national2condensed"
        >
          Kommer snart
        </p>
      </div>
      <div class="flex flex-wrap items-end gap-4 md:flex-nowrap">
        <UButtonNew
          v-if="viewType === 'list'"
          bold
          size="md"
          :to="`/produkter/${product.urlFormatProductTitle}`"
        >
          Les mer

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
        <UButtonNew
          v-if="
            isProductReleased &&
            (hasValidLicence ||
              viewType === 'detail' ||
              productHasFreeVariant)
          "
          bold
          :pill="viewType !== 'detail'"
          :theme="viewType !== 'detail' ? 'cta' : undefined"
          size="md"
          :variant="viewType !== 'detail' ? 'filled' : undefined"
          @click="goToProductContentButtonHandler"
        >
          {{ !hasValidLicence ? "Prøv gratis" : "Til produktet" }}

          <span class="sr-only"
            >om Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>

        <UButtonNew
          v-if="
            product?.hasAtLeastOneReleasedProduct &&
            !productHasFreeVariant &&
            !hasValidLicence
          "
          variant="filled"
          theme="cta"
          pill
          size="md"
          data-pendo="forside-kjop-tilgang"
          bold
          :to="`/produkter/${product.urlFormatProductTitle}/betaling`"
        >
          Kjøp
          <span class="hidden sm:inline">tilgang</span>
          <span class="sr-only"
            >til Kunne {{ product.productTitle }}</span
          >
        </UButtonNew>
        <UButtonNew
          v-else-if="!isProductReleased && hasInterestForm"
          :to="`/produkter/${product.urlFormatProductTitle}/registrer-interesse`"
          :data-pendo="`forside-hold-meg-oppdatert-${product.urlFormatProductTitle}`"
          variant="filled"
          theme="cta"
          size="md"
          pill
        >
          Hold meg oppdatert
        </UButtonNew>
      </div>
    </div>
  </div>
</template>
