import type {
  ImageField,
  ImageRelationField,
} from "~/models/Content/BaseField"
import type {
  ImageAsset,
  MatrixEntries,
  MediaMetadata,
  RelationList,
  Tag,
  Url,
  User,
  BinaryFile,
} from "~/models/Content/SearchResult"
import { selectionTypes } from "./selection-types"

export const formatStringField = (value: unknown): string => {
  return String(value ?? "")
}

export const formatTextField = (value: unknown): string => {
  return String(value ?? "")
}

export const formatIntegerField = (value: any): number | null => {
  return parseInt(value) || null
}

export const formatDateField = (value: any): Date | null => {
  if (!value || typeof value !== "object") return null

  for (const key in value) {
    const fieldValue = value[key]

    if (fieldValue && !isNaN(Date.parse(fieldValue))) {
      return new Date(fieldValue)
    }
  }

  return null
}

export const formatUrlField = (value: unknown): Url | null => {
  if (!value || typeof value !== "object") return null

  if ("link" in value && "text" in value) {
    return value as Url
  } else {
    return null
  }
}

export const formatRichTextField = (value: unknown): string => {
  if (!value || typeof value !== "object") return ""

  const html5Content = (value as { xhtml5edit?: string })?.xhtml5edit
  if (!html5Content) return ""

  // Remove XML declaration and clean up the HTML
  // Example: <?xml version=\"1.0\" encoding=\"UTF-8\"?>\n<section xmlns=\"http://ez.no/namespaces/ezpublish5/xhtml5/edit\"><p>Into <a href=\"ezlocation://637\">here</a></p></section>\n
  // Expected output: <p>Into <a href="ezlocation://637">here</a></p>
  const cleanedHtml = html5Content
    .replace(/^<\?xml[^>]*>\n/, "") // Remove XML declaration
    .replace(/<section[^>]*>|<\/section>/g, "") // Remove section tags
    .trim()
  return cleanedHtml
}

export const formatImageAssetField = (
  value: unknown,
): ImageAsset | null => {
  if (!value) {
    return null
  }

  const imageAsset = value as ImageRelationField
  if (!imageAsset.destinationContentId) {
    return null
  }

  return {
    id: parseInt(imageAsset.destinationContentId),
    src: `${import.meta.env.VITE_IBEXA_BASEURL}/image/${imageAsset.destinationContentId}/image/fullwidth`,
    alt: imageAsset.alternativeText ?? "",
    width: imageAsset.width ? parseInt(imageAsset.width) : undefined,
    height: imageAsset.height
      ? parseInt(imageAsset.height)
      : undefined,
    focalPointX: imageAsset.additionalData?.focalPointX
      ? parseInt(imageAsset.additionalData.focalPointX)
      : undefined,
    focalPointY: imageAsset.additionalData?.focalPointY
      ? parseInt(imageAsset.additionalData.focalPointY)
      : undefined,
  }
}

export const formatImageField = (
  value: unknown,
): ImageAsset | null => {
  if (!value || typeof value !== "object") return null

  const imageAsset = value as ImageField
  if (!imageAsset?.uri) return null

  return {
    id: null,
    src: imageAsset.uri,
    alt: imageAsset.alternativeText ?? "",
    width: imageAsset.width ? parseInt(imageAsset.width) : undefined,
    height: imageAsset.height
      ? parseInt(imageAsset.height)
      : undefined,
  }
}

export const formatObjectRelationField = (
  value: unknown,
): RelationList => {
  if (!value || typeof value !== "object") {
    return { contendIds: [] }
  }

  const fieldValue = value as object
  let contentIds: number[] = []

  if ("destinationContentIds" in fieldValue) {
    contentIds =
      (fieldValue as { destinationContentIds: number[] | null })
        .destinationContentIds ?? []
  } else if ("destinationContentId" in fieldValue) {
    const id = (fieldValue as { destinationContentId: number | null })
      .destinationContentId
    if (id) {
      contentIds = [id]
    } else {
      contentIds = []
    }
  }

  return {
    contendIds: contentIds.map(id => Number(id)),
  }
}

export const formatTagsField = (value: unknown): Tag[] => {
  if (!Array.isArray(value)) return []

  return value.map(tag => ({
    key: String(tag.remote_id ?? ""),
    value: tag.keywords?.["eng-GB"] ?? tag.keywords?.["nor-NO"] ?? "",
  }))
}

export const formatBooleanField = (value: unknown): boolean => {
  if (typeof value === "string") {
    return value.toLowerCase() !== "false"
  }
  return Boolean(value)
}

export const formatAuthorField = (value: unknown): User | null => {
  if (!value || !Array.isArray(value) || !value[0]) {
    return null
  }

  const author = value[0] as User

  return {
    id: Number(author.id),
    name: author.name ?? "",
    email: author.email ?? "",
  }
}

export const formatSelectionField = (
  value: unknown,
  contentType: string,
  fieldKey: string,
): string => {
  if (!Array.isArray(value) || value.length === 0) {
    return ""
  }

  const selectionValue = String(value[0])
  const mappings = selectionTypes[contentType]?.[fieldKey]

  if (!mappings) {
    console.error(
      `Missing selection type mapping for contentType: "${contentType}", fieldKey: "${fieldKey}", value: "${selectionValue}"`,
    )
    return selectionValue
  }

  return mappings[selectionValue] || selectionValue
}

export const formatMatrixField = (
  rawValue: unknown,
): MatrixEntries => {
  if (!rawValue || typeof rawValue !== "object") {
    return { entries: [] }
  }

  try {
    const matrixData =
      typeof rawValue === "string" ? JSON.parse(rawValue) : rawValue
    return {
      entries: Array.isArray(matrixData.entries)
        ? matrixData.entries
        : [],
    }
  } catch (error) {
    console.error("Error parsing matrix field:", error)
    return { entries: [] }
  }
}

export const formatMetadataField = (
  rawValue: unknown,
): MediaMetadata => {
  if (!rawValue || typeof rawValue !== "object") {
    return {
      cmid: null,
      contentItemId: "",
      docType: "",
      duration: "",
      elementURI: "",
      embedCode: null,
      embedURL: "",
      ezObjectId: null,
      image: "",
      materialID: null,
      mimeType: "",
      pageID: null,
      rdfResource: "",
      returnPath: "",
    }
  }
  rawValue = JSON.parse(
    JSON.stringify(rawValue).replaceAll(
      "media.lokus.no",
      "media.aschehoug.cloud",
    ),
  )
  return rawValue as MediaMetadata
}

export const formatBinaryFileField = (
  value: unknown,
): BinaryFile | null => {
  if (!value || typeof value !== "object") return null

  const binaryFile = value as Record<string, any>
  return {
    downloadCount: parseInt(binaryFile.downloadCount) || 0,
    fileName: String(binaryFile.fileName || ""),
    fileSize: parseInt(binaryFile.fileSize) || 0,
    id: String(binaryFile.id || ""),
    inputUri: binaryFile.inputUri || null,
    mimeType: String(binaryFile.mimeType || ""),
    path: binaryFile.path || null,
    uri: String(binaryFile.uri || ""),
    url: String(binaryFile.url || ""),
  }
}
