import { loginPageUrl } from "~/utilities/urls"

export const useLoginPageUrl = () => {
  const productVariantStore = useProductVariantsStore()
  const route = useRoute("product")

  const loginUrl = computed(() => {
    if (productVariantStore.product) {
      return loginPageUrl(productVariantStore.product)
    } else if (route.params.product) {
      return loginPageUrl(route.params.product)
    } else {
      return undefined
    }
  })

  return loginUrl
}
