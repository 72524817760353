import { acceptHMRUpdate, defineStore } from "pinia"

import {
  getMarketingAndPages,
  MarketingAndPages,
} from "~/api/getMarketingAndPages"

export const useMarketingStore = defineStore("marketing", () => {
  const pagesAndMarketingItems = ref<MarketingAndPages>()
  async function getItems(): Promise<MarketingAndPages> {
    const data = await getMarketingAndPages()
    return data
  }

  function hydrateStore() {
    getItems().then(data => {
      pagesAndMarketingItems.value = data
    })
  }

  return {
    pagesAndMarketingItems,
    hydrateStore,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useMarketingStore, import.meta.hot),
  )
