import { MathTask } from "~/types/article"
import { MathTaskIbexa } from "~/models/Content/schemas"
import { ContentType } from "~/models/Content/ContentType"

export default (
  articleId: number,
  mathTasks?: MathTaskIbexa[],
  formatHtml?: (html: string) => string,
): MathTask | undefined => {
  if (!mathTasks || mathTasks?.length === 0) return undefined

  const assignments: MathTask["assignments"] = []

  // TODO: Fix types
  mathTasks.forEach(m => {
    if (m.contentTypeIdentifier === ContentType.MathTaskIbexa) {
      assignments.push({
        id: m.locationId,
        type: m.assignmentType ?? "",
        text: formatHtml?.(m.assignmentText?.html ?? "") ?? "",
        priority: m.priority,
      })
    }
  })

  return {
    articleId,
    assignmentType:
      assignments.find(a => a.type.length > 0)?.type ?? "",
    assignments: assignments.sort((a, b) => a.priority - b.priority),
  }
}
