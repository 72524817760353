<script setup lang="ts">
import { ref, computed } from "vue"

const showBanner = ref(true)

const closeBanner = () => {
  showBanner.value = false
}

const maintenanceDate = new Date("2024-12-16")
const today = new Date()
const shouldShowBanner = computed(() => {
  return today <= maintenanceDate
})
</script>

<template>
  <div
    v-if="showBanner && shouldShowBanner"
    class="absolute z-50 px-6 py-6 mx-4 text-center rounded-lg shadow-lg md:w-1/4 md:right-8 top-12 bg-dark-blue-dark font-national2"
  >
    <div class="flex items-start justify-between gap-4">
      <div class="flex items-center gap-4">
        <p class="text-lg text-left text-white">
          <span class="font-bold">Mandag 16. desember </span>vil Kunne
          være utilgjengelig i en kort periode på grunn av
          vedlikehold.
        </p>
      </div>
      <button
        class="rounded-full p-1.5 hover:bg-dark-blue-darker"
        aria-label="Lukk vedlikeholdsvarsel"
        @click="closeBanner"
      >
        <UIcon name="close" size="sm" class="text-white" />
      </button>
    </div>
  </div>
</template>
