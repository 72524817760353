import type { Subject } from "~/types/subject"
import type { Folder } from "~/types/folder"
import type { BaseItem } from "~/models/Content/BaseItem"
import useContentApi from "./getContent"
import { ContentType } from "~/models/Content/ContentType"
import { Video } from "~/types/media"
interface SubjectResponse {
  subjects: Subject[]
  folders: Folder[]
}

export default async function (
  locationId: number | undefined,
): Promise<SubjectResponse> {
  if (!locationId) {
    throw new Error("No locationId provided")
  }

  const { getContent } = useContentApi()

  // Get both folders and subjects in a single query
  const items = await getContent<Folder | Subject>(
    {
      parentLocationIdCriterion: [locationId],
      contentTypeCriterion: [ContentType.Folder, ContentType.Chapter],
      sortField: "priority",
      sortOrder: "asc",
    },
    undefined,
    undefined,
    [],
    { includeChildren: true, maxDepth: 1 },
  )

  // Separate folders and subjects
  const folders = items.filter(
    (item): item is Folder & BaseItem =>
      item.contentTypeIdentifier === ContentType.Folder,
  )

  const directSubjects = items.filter(
    (item): item is Subject =>
      item.contentTypeIdentifier === ContentType.Chapter,
  )

  // Get subjects from folder children
  const folderSubjects = folders.flatMap(
    folder =>
      folder.children?.filter(
        (item): item is Subject =>
          item.contentTypeIdentifier === ContentType.Chapter,
      ) ?? [],
  )
  // Combine all subjects
  const subjects = [...directSubjects, ...folderSubjects]

  subjects.forEach(s => {
    const introPageOrder = s.relationsIds

    s.introPages = s.relatedItems
      ?.filter(
        (item): item is BaseItem =>
          item.contentTypeIdentifier === ContentType.ChapterPage,
      )
      .sort((a, b) => {
        const indexA = introPageOrder.indexOf(a.contentId)
        const indexB = introPageOrder.indexOf(b.contentId)
        return indexA - indexB
      })
  })

  subjects.forEach(s => {
    s.introPages.forEach(p => {
      p.video = p.relatedItems?.find(
        (item: BaseItem): item is Video =>
          item.contentTypeIdentifier === ContentType.Video,
      )
    })
  })

  return {
    subjects,
    folders,
  }
}
