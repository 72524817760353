import {
  object,
  string,
  number,
  boolean,
  nullable,
  optional,
  picklist,
} from "valibot"
import { VideoSchema } from "./media.schema"

export const RelationImageSchema = object({
  uri: string(),
  height: optional(number()),
  width: optional(number()),
  variation: optional(
    object({
      uri: optional(string()),
    }),
  ),
  alternativeText: optional(string()),
})

export const BaseRelationSchema = object({
  identifier: string(),
  title: string(),
  html: nullable(string()),
  resourceUrl: string(),
  image: optional(RelationImageSchema),
  imageCaption: optional(string()),
  video: nullable(VideoSchema),
  appendText: optional(string()),
})

export const SourceRelationSchema = object({
  ...BaseRelationSchema.entries,
  identifier: picklist(["source"]),
  resource: object({
    type: string(),
    author: string(),
    title: string(),
    edition: optional(string()),
    publisher: optional(string()),
    publishYear: optional(number()),
    publishDate: optional(string()),
    url: optional(string()),
  }),
})

export const TermRelationSchema = object({
  ...BaseRelationSchema.entries,
  identifier: picklist(["term"]),
  resource: object({
    definition: string(),
    explanation: string(),
    isFlashcard: boolean(),
  }),
})

export const PersonRelationSchema = object({
  ...BaseRelationSchema.entries,
  identifier: picklist(["person"]),
  resource: object({
    birthYear: number(),
    deceasedYear: optional(number()),
    about: string(),
  }),
})

export const ExplainerRelationSchema = object({
  ...BaseRelationSchema.entries,
  identifier: picklist(["sidenote", "explainer"]),
  html: string(),
})

export const WhatIsRelationSchema = object({
  ...BaseRelationSchema.entries,
  identifier: picklist(["what_is"]),
  resource: object({
    explanation: string(),
  }),
})
