export const FOLDER_REGEX = /\/m_[^/]+/

export const variantKeys = [
  "uia",
  "uib",
  "uio",
  "uis",
  "ped",
  "ntnu",
  "bi",
  "kristiania",
  "nhh",
  "oslomet",
  "felles",
] as const

export type VariantKeys = (typeof variantKeys)[number]

export const variantFullNameMap = {
  uia: "Universitetet i Agder",
  uib: "Universitetet i Bergen",
  uio: "Universitetet i Oslo",
  uis: "Universitetet i Stavanger",
  ped: "Pedagogikk",
  ntnu: "NTNU",
  bi: "BI",
  kristiania: "Kristiania",
  nhh: "NHH",
  oslomet: "OsloMet",
  felles: "Felles",
} as const satisfies Record<VariantKeys, string>
