<script setup lang="ts">
import { Article, ArticleTemplate } from "../../../types/article"
import UIcon from "~/components/UIcon.vue"
import { useLoginPageUrl } from "../../../composables/useLoginPageUrl"
const props = defineProps({
  title: {
    type: String,
    default: "Laster...",
  },
  data: {
    type: Object as PropType<Article>,
    default: () => {},
  },
})

const productContent = useProductContentStore()

const loginPageUrl = useLoginPageUrl()

const template: ArticleTemplate = props.data.template
</script>
<template>
  <div
    class="relative flex h-full w-full overflow-clip rounded-xl bg-platform-paper font-national2condensed text-black"
    :class="{
      'flex-row-reverse': template === 'UTextWithImageArticle',
    }"
  >
    <img
      v-if="data.img && data.img.src"
      class="h-full w-[50%] object-cover"
      :class="{ 'brightness-50': data.restricted }"
      :src="data.img?.src"
      :alt="data.img?.alt"
    />

    <div
      class="relative flex h-full w-full flex-col bg-platform-paper p-8 items-start"
      :class="{
        'max-w-[50%]': data.img && data.img.src,
        'px-5 lg:px-32': !data?.img?.src,
      }"
    >
      <div>
        <h4
          class="text-md hidden pb-3.5 font-national2 font-extrabold uppercase text-darker lg:block lg:text-lg"
        >
          {{ productContent.activeSubject?.title }}
        </h4>
        <h3
          class="mb-2 line-clamp-3 max-w-96 overflow-clip whitespace-pre-line font-national2condensed text-2xl font-medium lg:mb-6 lg:mt-0 lg:text-4xl xl:text-5xl"
          :class="{
            'mt-6': data.restricted,
          }"
        >
          {{ title }}
        </h3>
      </div>
      <URichText
        v-if="!data.restricted"
        inert
        :html="data.body?.html.trim()"
        class="w-full overflow-clip whitespace-break-spaces text-xs lg:text-lg [&_.text-link]:no-underline"
      />
      <p
        v-else
        class="my-auto font-national2 text-xs font-bold text-dark lg:text-lg"
      >
        Kjøp tilgang for å se innholdet i dette kapittelet
        <span class="whitespace-nowrap font-normal"
          >Allerede tilgang?
          <UButtonNew
            v-if="loginPageUrl"
            :to="loginPageUrl"
            variant="quite"
            size="xs"
            theme="medium"
            no-padding-left-and-right
            class="inline underline"
          >
            Logg inn
          </UButtonNew>
          <template v-else>Logg inn</template>
        </span>
      </p>
    </div>
    <UIcon
      v-if="data.restricted"
      name="lock-filled"
      class="absolute left-4 top-4 text-white"
      :class="{
        '!text-darker':
          !data.img?.src || template === 'UTextWithImageArticle',
      }"
      size="lg"
    />
  </div>
</template>
