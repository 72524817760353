import type { Article } from "~/types/article"
import type { Subject } from "~/types/subject"
import { acceptHMRUpdate, defineStore } from "pinia"
import getDataFormat from "~/api/getSubjects"
import getArticlesDataFormat from "~/api/getArticles"

import {
  formatRestrictedArticles,
  formatSubjectPage,
} from "~/utilities/articles"
import {
  findNextValidSubject,
  findPreviousValidSubject,
} from "~/utilities/subjectUtils"
import {
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/vue-query"
import { sanitizeForUrl } from "~/utilities/urls"

export const useProductContentStore = defineStore(
  "productContent",
  () => {
    /** State */
    const productStore = useProductVariantsStore()
    const articleSubjectId = ref<number | undefined>(undefined)
    const articleId = ref<number | undefined>(undefined)
    const nextSubject = ref<Subject | undefined>(undefined)
    const previousSubject = ref<Subject | undefined>(undefined)

    const activeSubject = computed(() =>
      subjects.value.find(s => s.id === articleSubjectId.value),
    )

    const nextArticle = ref<Article | undefined>(undefined)
    const previousArticle = ref<Article | undefined>(undefined)

    const activeArticle = computed(() => {
      if (articles.value.length === 0) return null
      const res = articles.value.find(a => a.id === articleId.value)

      return res
    })

    /** Getters */
    const subjects = computed<Subject[]>(() => {
      const folders = subjectsAndFolders.data.value?.folders ?? []

      let allSubjects = subjectsAndFolders.data.value?.subjects ?? []

      if (!productStore.activeVariant?.userHasLicence) {
        allSubjects = allSubjects.map(s => {
          // Temporarily solution for free subjects, should create Boolean field on subject
          if (s.description !== "<p>Gratis</p>") {
            return {
              ...s,
              totalChildren: 0,
            }
          } else {
            return s
          }
        })
      }

      return allSubjects.filter(
        s =>
          s.parentLocationId ===
            productStore.activeVariant?.locationId ||
          folders.some(f => f.id === s.parentLocationId),
      )
    })

    /** Getters */
    const subjectNav = computed(() =>
      subjects.value
        .map(i => {
          return {
            id: i.id,
            parentLocationId: i.parentLocationId,
            text: i.title,
            frontendHref: i.frontendHref,
            priority: i.priority,
            hidden: i.hidden,
            totalChildren: i.totalChildren,
            type: i.type,
          }
        })
        .sort((a, b) => a.priority - b.priority),
    )

    const articleNav = computed(() =>
      articles.value.map(i => {
        return {
          id: i.id,
          parentLocationId: i.parentLocationId,
          text: i.title,
          frontendHref: i.frontendHref,
          priority: i.priority,
          hidden: i.hidden,
          restricted: i.restricted || false,
        }
      }),
    )

    const queryClient = useQueryClient()

    const contentQueryKey = ["content"] as const

    async function invalidateContent() {
      await queryClient.invalidateQueries({
        queryKey: contentQueryKey,
      })
    }

    /** Fetch subjects and folders */
    const subjectsAndFolders = useQuery({
      queryKey: [
        ...contentQueryKey,
        "subjects-and-folders",
        computed(() => productStore.activeVariant?.locationId),
      ] as const,
      staleTime: 1000 * 60 * 60 * 6, // 6 hours
      enabled: computed(() => !!productStore.activeVariant),
      async queryFn({ queryKey }) {
        const locationId = queryKey[2]
        if (typeof locationId !== "number") {
          throw new Error("No locationId provided")
        }

        const data = await getDataFormat(locationId)

        data.folders.sort((a, b) => a.priority - b.priority)

        if (data.folders.length <= 0) {
          data.subjects.sort((a, b) => a.priority - b.priority)
        } else {
          const sortedSubjects: Subject[] = []
          for (const folder of data.folders) {
            const subjects = data.subjects
              .filter(subj => subj.parentLocationId === folder.id)
              .sort((a, b) => a.priority - b.priority)
            sortedSubjects.push(...subjects)
          }

          data.subjects = sortedSubjects
        }

        data.subjects.forEach(s => {
          s.frontendHref = {
            path: `/produkter/${productStore.activeProduct?.productKey}/${productStore.activeVariant?.variantKey}/${sanitizeForUrl(s.title)}`,
            query: {
              kapittel: s.id,
            },
          }
        })

        return data
      },
    })

    /** Fetch articles */
    const articlesQuery = useQueries({
      queries: [previousSubject, activeSubject, nextSubject].map(
        sub => ({
          queryKey: [
            ...contentQueryKey,
            "articles",
            computed(() => sub.value?.id),
            computed(() => sub.value?.totalChildren),
          ],
          enabled: computed(() => !!sub.value),
          staleTime: 1000 * 60 * 60 * 6, // 6 hours
          async queryFn() {
            const s = sub.value

            if (!s) {
              return []
            }

            let articles: Article[] = []
            // if totalChildren is 0, it's a restricted subject
            // so we have to format the articles in restricted format
            if (s.totalChildren === 0) {
              const articlesBeforeRestricted =
                await getArticlesDataFormat(s.id)

              articles = formatRestrictedArticles(
                articlesBeforeRestricted,
                s.id,
              )
            } else {
              articles = await getArticlesDataFormat(s.id)
              const subjectPage = formatSubjectPage(s)
              if (subjectPage) articles.unshift(subjectPage)
            }

            return articles.map(a => {
              if (!a.frontendHref) {
                const subjectUrlFraction = sanitizeForUrl(
                  activeSubject.value?.title,
                )
                const articleUrlFraction = sanitizeForUrl(a.title)

                a.frontendHref = {
                  path: `/produkter/${productStore.activeProduct?.productKey}/${productStore.activeVariant?.variantKey}/${subjectUrlFraction}/${articleUrlFraction}`,
                  query: {
                    artikkel: a.id,
                    kapittel: a.parentLocationId ?? 0,
                  },
                }
              }

              return a
            })
          },
        }),
      ),
    })

    const allArticles = computed(() => {
      const all = articlesQuery.value.flatMap(it => it.data ?? [])

      const sortedArticles: Article[] = []

      const subjects = subjectsAndFolders.data.value?.subjects ?? []

      for (const subject of subjects) {
        const articles = all
          .filter(article => article.parentLocationId === subject.id)
          .sort((a, b) => {
            // Intro slides should always be the first article in a product
            if (
              a.template === "USlidesArticle" &&
              b.template !== "USlidesArticle"
            ) {
              return -1
            }
            if (
              a.template !== "USlidesArticle" &&
              b.template === "USlidesArticle"
            ) {
              return 1
            }

            return a.priority - b.priority
          })

        sortedArticles.push(...articles)
      }

      return sortedArticles
    })

    const articles = computed<Article[]>(() =>
      allArticles.value.filter(
        a => a.parentLocationId === activeSubject.value?.id,
      ),
    )

    /** Fetch next and previous subjects */
    watchEffect(() => {
      if (!activeSubject.value) {
        previousSubject.value = undefined
        nextSubject.value = undefined
        return
      }

      const currentSubjectIndex = subjects.value.findIndex(
        s => s.id === activeSubject.value?.id,
      )

      // Find previous subject
      previousSubject.value = findPreviousValidSubject(
        subjects.value,
        currentSubjectIndex - 1,
      )

      // Find next subject
      nextSubject.value = findNextValidSubject(
        subjects.value,
        currentSubjectIndex + 1,
      )
    })

    /** Fetch next and previous articles */
    watchEffect(() => {
      if (!activeArticle.value) {
        previousArticle.value = undefined
        nextArticle.value = undefined
      }

      const lastIndex = allArticles.value.length - 1
      const currentIndex = allArticles.value.findIndex(
        a => a.id === activeArticle.value?.id,
      )
      if (currentIndex === 0) {
        previousArticle.value = undefined
        nextArticle.value = allArticles.value[currentIndex + 1]
      } else if (
        currentIndex === lastIndex &&
        articles.value.length > 1
      ) {
        previousArticle.value = allArticles.value[currentIndex - 1]
        nextArticle.value = undefined
      } else {
        previousArticle.value = allArticles.value[currentIndex - 1]
        nextArticle.value = allArticles.value[currentIndex + 1]
      }
    })

    return {
      subjects,
      articles,
      activeArticle,
      nextArticle,
      previousArticle,
      folders: computed(
        () => subjectsAndFolders.data.value?.folders ?? [],
      ),
      subjectNav,
      articleNav,
      articleSubjectId,
      activeSubject,
      nextSubject,
      previousSubject,
      noSubjectsFound: computed(
        () =>
          !activeSubject.value &&
          !subjectsAndFolders.isFetching.value,
      ),
      noArticlesFound: computed(
        () =>
          !activeArticle.value &&
          !articlesQuery.value.some(it => it.isFetching),
      ),
      articleId,
      allArticles,
      hydrationInProgress: computed(
        () =>
          subjectsAndFolders.isFetching.value ||
          articlesQuery.value.some(it => it.isFetching),
      ),
      invalidateContent,
    }
  },
)

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useProductContentStore, import.meta.hot),
  )
