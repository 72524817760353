import * as Sentry from "@sentry/vue"
import {
  VariantKeys,
  variantKeys,
} from "../../src/utilities/constants"
import { formatForURL } from "../../src/utilities/composables"

export const formatProductUrl = (product: string) => {
  const variant = extractProductVariant(product)

  const productKey = buildProductKey(product)

  if (productKey && variant) {
    return {
      productKey,
      variant: variant.toLowerCase() as VariantKeys,
    }
  } else {
    return undefined
  }
}

export const extractProductVariant = (title: string) => {
  const words = title.split(" ")

  if (words.length < 2) {
    Sentry.captureException(
      "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
    )

    console.error(
      "Wrong structure in product variant title, expected title to be at least two words on the form [productTitle + productVariant].",
    )
    console.error("Product Title Found: ", title)
    return undefined
  }

  // The variant (ex. "UiO" or "NTNU") is the last word in the title
  const variant = words[words.length - 1]

  if (!isVariantKey(variant)) {
    Sentry.captureException(
      `Unsupported variant [${variant}] used in product [${title}]`,
    )
    return undefined
  } else {
    return variant
  }
}

function isVariantKey(value: string): value is VariantKeys {
  return variantKeys.some(
    v => v.toLowerCase() === value.toLowerCase(),
  )
}

export function buildProductKey(ibexaTitle: string) {
  // We only want the part after '/' and before the last '-' in the URL, ex. '/kunne/exphil-uio' -> 'exphil'

  const segments = ibexaTitle.split(" ")
  return formatForURL(
    segments.slice(0, segments.length - 1).join("-"),
  )
}
